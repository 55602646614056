import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const COMPANY_NAME = "Platforma Biznesu"

const routes = [
  {
    path: '/',
    name: 'Home | ' + COMPANY_NAME,
    component: () => import(/* webpackChunkName: "home" */ /* webpackMode: "lazy" */ '../views/Home.vue')
  },
  {
    path: '/products',
    name: 'Produkty | '  + COMPANY_NAME,
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/news',
    name: 'Nowości | ' + COMPANY_NAME,
    component: () => import(/* webpackChunkName: "news" */ /* webpackMode: "lazy" */ '../views/News.vue')
  },
/*  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/!* webpackChunkName: "settings" *!/ '../views/Settings.vue')
  },*/
  {
    path: '/products/qms',
    name: 'Produkty | Systemy kolejkowe',
    component: () => import(/* webpackChunkName: "qms-product" */ '../views/products/QmsProduct')
  },
  {
    path: '/products/mms',
    name: 'Produkty | Systemy multimedialne',
    component: () => import(/* webpackChunkName: "mms-product" */ '../views/products/MmsProduct')
  },
  {
    path: '/products/urbanitus',
    name: 'Produkty | Urbanitus | Miejski System Ochrony Uczestników Ruchu',
    component: () => import(/* webpackChunkName: "taps-product" */ '../views/products/TapsProduct')
  },
  {
    path: '/products/urbanitus/privacy',
    name: 'Polityka prywatności | Urbanitus | Miejski System Ochrony Uczestników Ruchu',
    component: () => import(/* webpackChunkName: "taps-privacy-product" */ '../views/products/TapsPrivacy')
  },
  {
    path: '/urbanitus',
    redirect: '/products/urbanitus',
  },
  {
    path: '/products/mobile',
    name: 'Produkty | Aplikacje mobilne',
    component: () => import(/* webpackChunkName: "mobile-product" */ '../views/products/MobileProduct')
  },
  {
    path: '/products/ledDEMOFrUBygUN',
    name: 'Produkty | Ekrany LED',
    component: () => import(/* webpackChunkName: "led-product" */ '../views/products/LedProduct')
  },
  {
    path: '/products/custom',
    name: 'Produkty | Rozwiązania dedykowane',
    component: () => import(/* webpackChunkName: "custom-product" */ '../views/products/CustomProduct')
  },
  {
    path: '/products/qms/features',
    name: 'Produkty | System Kolejkowy | Przegląd funkcjonalności',
    component: () => import(/* webpackChunkName: "qms-features-product" */ '../components/products/qms/QmsFeaturesOverview')
  },
  {
    path: '/products/qms/tdm/gallery',
    name: 'Produkty | System Kolejkowy | Automat Biletowy - Galeria',
    component: () => import(/* webpackChunkName: "qms-features-product" */ '../components/products/qms/QmsTdmGallery')
  },
  {
    path: '/privacy',
    name: 'Polityka prywatności',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/about',
    name: 'O firmie | ' + COMPANY_NAME,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Platforma Biznesu | systemy kolejkowe, biletomaty, systemy multimedialne, rezerwacje online.';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.name || DEFAULT_TITLE;
  });
});

export default router
