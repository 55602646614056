<template>
  <v-footer
      color="primary lighten-1"
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-btn
          v-for="(link, index) in menu"
          :key="index"
          color="white"
          text
          class="my-3"
          :to="link.to"
          :href="link.href"
          :target="link.href ? '_blank' : ''"
      >
        {{ link.label }}
      </v-btn>
      <v-col
          class="primary lighten-2 py-4 text-center white--text"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>
        Platforma Biznesu</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import config from "@/config";

export default {
  data: () => ({
    //TODO: missing translations or restful request
    menu: config.MENU_ITEMS,
  }),
}
</script>
