<template>
  <div id="app">
    <v-app>
      <MobileNav :menu="mobileMenu" />
      <v-app-bar
          app
          color="primary"
          dark
          elevate-on-scroll
          class="hidden-sm-and-down mb-6"
      >
        <v-card color="transparent" class="d-flex align-center elevation-0" href="/" >
          <v-img
              alt="Platforma Biznesu"
              class="shrink ml-1"
              contain
              :src="require('./assets/logo_pb.png')"
              transition="scale-transition"
              width="120"
          />

        </v-card>
        <v-spacer></v-spacer>

        <v-btn color="primary" :href="shopUrl" target="_blank">
          <v-icon>mdi-cart</v-icon>
          <div class="ml-2">{{shopLabel}}</div>
        </v-btn>
        <ProductsTopBar class="mr-1" />


        <v-btn
            class="mr-1"
            v-for="item in menu"
            :key="item.id"
            :to="item.to"
            text
        >
          <v-icon v-if="item.icon">{{item.icon}}</v-icon>
          <span v-else class="mr-2">{{ item.label }}</span>
        </v-btn>
      </v-app-bar>
      <v-container fluid class="mt-8 pt-8 ma-0 pa-0">
        <v-slide-x-transition mode="out-in">
          <router-view/>
        </v-slide-x-transition>
      </v-container>
      <v-container fluid class="ma-0 pa-0 mt-5">
        <Footer />
      </v-container>
    </v-app>

  </div>
</template>


<script>
import ProductsTopBar from "@/components/ProductsTopBar";
import Footer from "@/components/Footer";
import MobileNav from "@/components/MobileNav";
import config from "./config"


export default {
  components: {MobileNav, Footer, ProductsTopBar},

  computed: {

    mobileMenu() {
      return config.MENU_ITEMS;
    },

    shopUrl() {
      return config.PS_SHOP_URL;
    },

    shopLabel() {
      return config.PS_SHOP_LABEL;
    },

    menu() {
      let menuItems = config.MENU_ITEMS;
      if(config.SHOW_DEBUG_CONTENT)
        return menuItems

      let filtered = []
      for (let i=0; i<menuItems.length;i++) {
        if(menuItems[i].debug === true || menuItems[i].only_mobile)
          continue;

        filtered.push(menuItems[i])
      }
      return filtered;
    }
  },
}


</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
