import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import draggable from 'vuedraggable';
import i18n from './i18n'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

Vue.component('draggable', draggable);

Vue.use(VueVideoPlayer)


Vue.config.productionTip = false
Vue.use(Vuex)

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
