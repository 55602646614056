const SHOP_URL = "https://sklep.platformabiznesu.net"

export default {
    HOME_PAGE: '/',
    SHOW_DEBUG_CONTENT: false,
    PS_SHOP_URL: SHOP_URL,
    PS_SHOP_LABEL: "Sklep",
    MENU_ITEMS: [
        {id: 10, label: "Sklep", href: SHOP_URL, only_mobile: true},
        {id: 2, label: "Nowości", to : '/news'},
        {id: 4, label: "O nas", to: '/about'},
        {id: 1, label: "Home", to: '/', icon: 'mdi-home'},
        {id: 3, label: "Urbanitus", to: '/products/urbanitus', only_mobile: true},
        {id: 5, label: "Siegla QMS", to: '/products/qms', only_mobile: true},
        {id: 6, label: "Siegla MMS", to: '/products/mms', only_mobile: true},
        // {id: 7, label: "Siegla LED", to: '/products/led', only_mobile: true},
        {id: 8, label: "Dedykowane aplikacje mobilne", to: '/products/mobile', only_mobile: true},
        {id: 9, label: "Dedykowane rozwiązania dla biznesu", to: '/products/custom', only_mobile: true},
        // {id: 3, to: '/settings', icon: 'mdi-cog', label: 'Ustawienia', debug: true}
    ]
}
