<template>
  <div class="text-center">
    <v-menu
        open-on-hover
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            depressed
            dark
            v-bind="attrs"
            v-on="on"
        >
          {{$t('products_label')}}

          <v-icon>{{menu ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
        </v-btn>
      </template>

      <v-card width="380">
        <v-list class="text-left"  subheader>
          <v-list-item-group>
            <v-sheet v-for="(product, index) in products" :key="index">
              <v-divider />
              <v-list-item :to="product.to">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 text-uppercase">
                    {{product.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{product.desc}}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon :color="product.icon_color ? product.icon_color : 'primary' " v-text="product.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-sheet>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: false,
    //TODO: missing translations or restful request
    products: [
      {name: "Systemy kolejkowe", desc: "Rozwiązania usprawniające proces obsługi interesantów.", to: '/products/qms'},
      {name: "Systemy multimedialne", desc: "Systemy prezentacji treści multimedialnych.", to: '/products/mms'},
      {name: "Urbanitus", desc: "Miejski System Ochrony Uczestników Ruchu", to: '/products/urbanitus', icon: 'mdi-bike' , icon_color: 'green darken-2'},
      {name: "Aplikacje mobilne", desc: "Oprogramowanie dla systemów Android oraz iOS.", to: '/products/mobile'},
      // {name: "Monitory LED", desc: "Reklamowe matryce LED", to: '/products/led'},
      {name: "Dedykowane produkty dla biznesu", desc: "Dopasowane rozwiązania dla Twojej firmy.", to: '/products/custom'}
    ]
  }),
}
</script>
