import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n'

import pl from "vuetify/lib/locale/pl";

Vue.use(Vuetify);
Vue.use(VueI18n);

export default new Vuetify({

    lang: {
        locales: {pl},
        current: 'pl'
    },

    theme: {
        themes: {
            light: {
                primary: '#113656',
                secondary: '#b3b3b3',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        }
    }
});
