<template>
<v-sheet>

  <v-app-bar
      color="primary"
      dark
      app
      elevate-on-scroll
  >

    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <v-img
        alt="Platforma Biznesu"
        class="shrink ml-1"
        contain
        :src="require('../assets/logo_pb.png')"
        transition="scale-transition"
        width="90"
    />

  </v-app-bar>

  <v-navigation-drawer
      v-model="drawer"
      absolute
      top
      temporary
  >
    <v-list
        nav
        dense
        class="text-left"
        style="position: fixed; top: 0;"
    >
      <v-list-item-group
          v-model="group"
          active-class="primary--text"
      >
        <v-list-item v-for="menuItem in menu"
                     :key="menuItem.id"
                     :to="menuItem.to"
                     :href="menuItem.href"
                     :target="menuItem.href ? '_blank' : ''"
        >
          <v-list-item-title>{{menuItem.label}}</v-list-item-title>
        </v-list-item>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</v-sheet>

</template>

<script>
export default {

  props: {
    menu: {
      type: Array
    },
  },

  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>


<style>

.v-navigation-drawer.v-navigation-drawer--absolute {
  position: fixed !important;
}

</style>
